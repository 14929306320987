import { Block } from "@components/Block";
import { SanitizeString } from "@components/SanitizeString";
import { SectionSeparator } from "@components/SectionSeparator";
import { SectionTitle } from "@components/SectionTitle";
import { APITypes } from "@lib/api.types";

interface ITitleBlocksProps {
  block: APITypes.Components.ContentBlocks.Title;
}

export default function TitleBlock({ block }: ITitleBlocksProps) {
  return (
    <Block>
      <SectionSeparator />
      <SectionTitle
        className={"text-3xl sm:text-5xl text-center mb-[-50px] sm:mb-0"}
      >
        <SanitizeString text={block.title.toUpperCase()} />
      </SectionTitle>
      {block.description && (
        <div className="md:px-32 xl:px-48 font-normal text-base md:font-light md:text-2xl">
          <div className="h-20" />
          <div className="">
            <SanitizeString text={block.description} />
          </div>
        </div>
      )}
    </Block>
  );
}
