import { Block } from "@components/Block";
import { APITypes } from "@lib/api.types";

interface VideoBlockProps {
  block: APITypes.Components.ContentBlocks.VideoBlock;
}

const VideoBlock = ({ block }: VideoBlockProps) => {
  return (
    <Block>
      <video controls>
        <source
          src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${block.video.data.attributes.url}`}
          type={block.video.data.attributes.mime}
        />
      </video>
    </Block>
  );
};

export default VideoBlock;
