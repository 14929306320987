import { Block } from "@components/Block";
import { Svg } from "@components/Svg";
import { APITypes } from "@lib/api.types";

interface IconCardsBlockProps {
  block: APITypes.Components.ContentBlocks.IconCards;
}

const IconCardsBlock = ({ block }: IconCardsBlockProps) => {
  const classes = (color: string) =>
    [
      "grid",
      "grid-cols-4",
      "gap-4",
      "items-center",
      "md:grid-cols-1",
      "md:justify-items-center",
      "rounded-2xl",
      "p-8",
      "font-normal",
      "text-2xl",
      "sm:text-center",
      "bg-greyCold",
    ]
      .filter(Boolean)
      .join(" ");

  return (
    <Block>
      <div
        className={`grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-4 lg:gap-4 md:mt-10`}
      >
        {block.icon_cards.map((card) => {
          return (
            <div key={card.title} className={classes(card.background_color)}>
              <Svg className="h-20" icon={card.icon} title={card.title} />
              <div className="col-span-3 md:col-span-1"> {card.title}</div>
            </div>
          );
        })}
      </div>
    </Block>
  );
};

export default IconCardsBlock;
