import { Block } from "@components/Block";
import { Svg } from "@components/Svg";
import { APITypes } from "@lib/api.types";
import Link from "next/link";

interface FullWidthButtonBlockProps {
  block: APITypes.Components.ContentBlocks.FullWidthButtonBlock;
}

const FullWidthButtonBlock = ({ block }: FullWidthButtonBlockProps) => {
  return (
    <Block>
      <div
        className={`grid col-span-4 bg-${block.button.color} rounded-2xl  text-black `}
      >
        <Link
          href={block.button.external_link ?? "/"}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-4 text-base text-2xl md:text-3xl font-bold p-10 justify-between"
        >
          {block.button.text}
          <Svg className="h-4 w-4" icon={"arrow-right"} title={"arrow-right"} />
        </Link>
      </div>
    </Block>
  );
};

export default FullWidthButtonBlock;
