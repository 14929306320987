import { Block } from "@components/Block";
import { ButtonWrapper } from "@components/Button";
import { APITypes } from "@lib/api.types";

interface ISingleButtonBlockProps {
  block: APITypes.Components.ContentBlocks.Button;
}

const SingleButtonBlock = ({ block: button }: ISingleButtonBlockProps) => {
  return (
    <Block>
      <div className="flex justify-center mt-4">
        <ButtonWrapper button={button} size="small" fill={false} />
      </div>
    </Block>
  );
};

export default SingleButtonBlock;
