import { Block } from "@components/Block";
import { APITypes } from "@lib/api.types";
import TextBlock from "./TextBlock";

interface ITextBlocksProps {
  block: APITypes.Components.ContentBlocks.TextBlocks;
}

const TextBlocks = ({ block }: ITextBlocksProps) => {
  return (
    <Block>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        {block.text_blocks.map((textBlock) => {
          return <TextBlock key={textBlock.id} block={textBlock} />;
        })}
      </div>
    </Block>
  );
};

export default TextBlocks;
