import { Block } from "@components/Block";
import { Image } from "@components/Image";
import { APITypes } from "@lib/api.types";

interface ImageBlockProps {
  block: APITypes.Components.ContentBlocks.ImageBlock;
}

const ImageBlock = ({ block }: ImageBlockProps) => {
  return (
    <Block>
      <Image
        image={block.image.data.attributes}
        alt={block.image.data.attributes.name}
      />
    </Block>
  );
};

export default ImageBlock;
