import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface IProps {
  richText: string;
}

export const RichText = ({ richText }: IProps) => {
  return <ReactMarkdown remarkPlugins={[remarkGfm]}>{richText}</ReactMarkdown>;
};
