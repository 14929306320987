import { Block } from "@components/Block";
import { SanitizeString } from "@components/SanitizeString";
import { Svg } from "@components/Svg";
import { APITypes } from "@lib/api.types";
import TagBlock from "./TagBlock";

interface ITextBlockProps {
  block: APITypes.Components.ContentBlocks.TextBlock;
}

const TextBlock = ({ block }: ITextBlockProps) => {
  return (
    <Block>
      <div className="grid grid-cols-1 gap-4 md:gap-4">
        {block.tag && (
          <div className="md:order-1">
            <TagBlock block={block.tag} />
          </div>
        )}
        <div className="text-2xl md:text-3xl md:order-1">
          <div className="flex flex-row gap-4">
            {block.icon && (
              <Svg
                className={`w-6 h-8 mt-[2px] sm:mt-[4px]`}
                icon={block.icon}
                title={block.icon}
              />
            )}
            <SanitizeString text={block.title.toUpperCase()} />
          </div>
        </div>
        <div className="text-base md:text-2xl font-light md:order-2">
          <SanitizeString text={block.text} />
        </div>
      </div>
    </Block>
  );
};

export default TextBlock;
