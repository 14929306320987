import { Block } from "@components/Block";
import { APITypes } from "@lib/api.types";
import { RichText } from "../components";

interface RichtextBlockProps {
  block: APITypes.Components.ContentBlocks.RichtextBlock;
}

const RichtextBlock = ({ block }: RichtextBlockProps) => {
  return (
    <Block>
      <div className="text-base font-normal blogText [&>ul]:list-disc [&>ul]:pl-4 [&>h2]:text-2xl [&>h2]:font-bold [&>h2]:pt-[40px] [&>h3]:font-bold [&>h3]:text-xl [&>h3]:pb-[16px] [&>h3]:pt-[40px] [&>iframe]:m-auto">
        <RichText richText={block.richtext} />
      </div>
    </Block>
  );
};

export default RichtextBlock;
