import { Block } from "@components/Block";
import { CardTeaser, TeaserGrid } from "@components/Teasers";
import { APITypes } from "@lib/api.types";

interface FeaturedTeamBlockProps {
  block: APITypes.Components.ContentBlocks.FeaturedTeam;
}

const FeaturedTeamBlock = ({ block }: FeaturedTeamBlockProps) => {
  return (
    <Block>
      <div className="grid justify-items-center">
        <TeaserGrid
          gap="sm"
          columns={block.team_members.data.length === 2 ? 2 : 3}
        >
          {block.team_members.data
            .sort((a, b) => a.id - b.id)
            .map(({ attributes, id }) => (
              <CardTeaser
                name={attributes.fullName}
                title={attributes.title}
                image={attributes.image.data.attributes}
                key={id}
                url={attributes.url}
              />
            ))}
        </TeaserGrid>
      </div>
    </Block>
  );
};

export default FeaturedTeamBlock;
